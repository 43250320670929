import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Avatar, Grid, Link } from "@material-ui/core";

import { useContactData } from "../hooks/useContactData";

const useStyles = makeStyles(theme => ({
  footer: {
    display: "block",
    margin: theme.spacing(2),
  },
  avatar: {
    display: "flex",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  footerGrid: {
    textAlign: "center",
  }
}));

const imgUrl = imgName => require(`../images/${imgName}`);

const Footer = () => {
  const classes = useStyles();
  const contactInfo = useContactData();

  return (
    <footer className={classes.footer}>
      <Grid container spacing={2} justify="center">
        <Grid item xs={6} sm={4} className={classes.footerGrid}><Typography>© {new Date().getFullYear()} Chris Weber</Typography></Grid>
        <Grid item container xs={6} sm={4} className={classes.footerGrid} justify="center">
          <Link href={contactInfo.twitter.link} target="_blank"><Avatar variant="rounded" className={classes.avatar} src={imgUrl(contactInfo.twitter.img)}>{contactInfo.twitter.name}</Avatar></Link>
          <Link href={contactInfo.linkedin.link} target="_blank"><Avatar variant="rounded" className={classes.avatar} src={imgUrl(contactInfo.linkedin.img)}>LinkedIn</Avatar></Link>
        </Grid>
      </Grid>
    </footer>
  )
}

export default Footer;