import PropTypes from "prop-types"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import AppBar from "@material-ui/core/AppBar"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import SchoolIcon from "@material-ui/icons/School"
import BusinessIcon from "@material-ui/icons/Business"
import HomeIcon from "@material-ui/icons/Home"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import DevicesIcon from '@material-ui/icons/Devices';

import clsx from "clsx"
import { Avatar } from "@material-ui/core"

const navItems = [
  {
    text: "Chris Weber",
    href: "/",
    icon: <HomeIcon />,
  },
  {
    text: "Education",
    href: "#education",
    icon: <SchoolIcon />,
  },
  {
    text: "Work",
    href: "#work",
    icon: <BusinessIcon />,
  },
  {
    text: "Stacks",
    href: "#stacks",
    icon: <DevicesIcon />,
  },
];


const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  titleTypography: {
    marginLeft: theme.spacing(1),
  },
}))

const ListItemLink = props => <ListItem button component="a" {...props} />

const NavigationMenuItem = props => (
  <ListItemLink {...props}>
    <ListItemIcon>{props.icon}</ListItemIcon>
    <ListItemText primary={props.text} />
  </ListItemLink>
)

const Header = ({ siteTitle }) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  const avatarImg = require("../images/chris-weber-avatar.png");

  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setOpen(open)
  }

  const sideMenu = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {navItems.map((navItem, index) => (
          <NavigationMenuItem key={"nav-" + index } {...navItem} />
        ))}
      </List>
    </div>
  )

  return (
    <>
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Avatar variant="rounded" src={avatarImg} />
          <Typography variant="h6" noWrap className={classes.titleTypography}>
            {siteTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {sideMenu()}
      </SwipeableDrawer>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
