import { graphql, useStaticQuery } from 'gatsby';

export const useContactData = () => {
  const { allContactYaml } = useStaticQuery(
    graphql`
    query ContactQuery {
      allContactYaml {
        nodes {
          linkedin {
            name
            link
            img
          }
          twitter {
            name
            img
            link
          }
        }
      }
    }    
    `
  );
  return allContactYaml.nodes.length > 0 ? allContactYaml.nodes[0] : {};
};
